import React from 'react'
import styled from 'styled-components'
import format from 'date-fns/format'
import Logo from './Icon'

export default function Footer() {
  const date = format(new Date(), 'yyyy')
  return (
    <StyledFooter>
      <Logo name="logo-paraaf" width="32px" height="32px" />© {date} Paul
      Rietveld
    </StyledFooter>
  )
}

const StyledFooter = styled.div`
  margin: 0 auto;
  max-width: 1194;
  padding: 30px 20px;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  position: relative;
  color: #6b6c6f;

  @media screen and (max-width: 768px) {
    font-size: 12px;
  }

  svg {
    margin: 0 auto;
    display: block;
    margin-bottom: 20px;
    color: #1d1d1b;
    @media (prefers-color-scheme: dark) {
      color: #fff;
    }
  }
`
