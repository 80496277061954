import styled from 'styled-components'
import Img from 'gatsby-image'

export const theme = {
  container: {
    background: '#fff',
    '@media (prefers-color-scheme: dark)': {
      background: '#000',
    },
  },
  arrow: {
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    fill: '#222',
    opacity: 0.3,
    transition: 'opacity 200ms',
    ':hover': {
      opacity: 1,
    },
  },
  arrow__size__medium: {
    borderRadius: 40,
    height: 40,
    marginTop: -20,

    '@media (min-width: 768px)': {
      height: 70,
      padding: 15,
    },
  },
  arrow__direction__left: { marginLeft: 0 },
  arrow__direction__right: { marginRight: 0 },
  close: {
    fill: '#666',
    opacity: 0.6,
    transition: 'all 200ms',

    ':hover': {
      opacity: 1,
    },
  },

  image: {
    transition: 'opacity 0.5s',
  },
  footer: {
    '@media (prefers-color-scheme: light)': {
      color: 'black',
    },
    '@media (prefers-color-scheme: dark)': {
      color: 'white',
    },
  },
  footerCount: {
    fontSize: '14px',
    color: 'rgba(0, 0, 0, 0.6)',
    '@media (prefers-color-scheme: dark)': {
      color: 'white',
    },
  },
}

export const ClickArea = styled.a`
  background-color: transparent;
  overflow: hidden;
  position: relative;
  display: block;
  cursor: pointer;
  line-height: 0;
  opacity: 1;
  transition: all 0.2s ease-in-out !important;

  :before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    opacity: 0;
    background: rgba(255, 255, 255, 0.1);
    width: 100%;
    height: 100%;
    transition: ease-out 0.2s;
  }
  &:hover {
    transform: scale(1.05);
    &:before {
      opacity: 1;
    }
  }
`
export const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 5px;

  @media screen and (max-width: 520px) {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
`

export const StyledImg = styled(Img)`
  display: inline-block;
  width: 100%;
  height: 100%;
  overflow: hidden;

  img {
    object-fit: contain !important;
    margin: 0;
    width: 100%;
  }
`

export const Box = styled.div`
  margin-bottom: 5px;
  overflow: hidden;
`

export const StyledLoader = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  display: flex;
  align-items: center;
  overflow: auto;
  div {
    margin: auto;
    max-height: 100%;
  }
`
